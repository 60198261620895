import * as React from "react";

export default function YouTubeEmbed({ youtubeId }) {
  return (
    <>
      <style>{`.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }`}</style>
      <div className="embed-container">
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}`}
          frameBorder="0"
          allowFullScreen
        />
      </div>
    </>
  );
}
