import * as React from 'react';
import YouTubeEmbed from '../components/YouTubeEmbed';
import Img from 'gatsby-image';
import Layout from './Layout';

export default function ServiceTemplate({ pageContext }) {
	const name = pageContext?.frontmatter?.name || '';
	const html = pageContext?.html || '';
	const excerpt = pageContext?.excerpt || '';
	// const timeToRead = pageContext?.timeToRead || "";
	const fixedImage = pageContext?.fixedImage || '';
	const fluidImage = pageContext?.fluidImage || '';
	const shortDescription = pageContext?.frontmatter?.shortDescription || '';
	const youtubeId = pageContext?.frontmatter?.youtubeId || '';

	return (
		<Layout title={`${name}`} description={shortDescription}>
			<Img className="h-32 mb-8 md:h-64" fluid={fluidImage} />
			{/* <header className="relative z-10 mb-20 -mt-16 md:-mt-48 md:mb-32">
				<div className="container">
					<h1>
						<span className="box-underline">{name}</span>
					</h1>
				</div>
			</header> */}
			<div className="container">
				<div className="prose" dangerouslySetInnerHTML={{ __html: html }} />
				<div className="max-w-4xl pt-8 mb-8">
					<YouTubeEmbed youtubeId={youtubeId} />
				</div>
			</div>
		</Layout>
	);
}
